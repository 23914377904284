import React from 'react'
import { Modal, Col, Row } from 'antd'
import { connect } from 'react-redux'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'

const mapStateToProps = ({ virtualSettings, agenda, virtualEvent }) => ({
  virtualSettings,
  agenda,
  virtualEvent,
})

@connect(mapStateToProps)
class Speaker2 extends React.Component {

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'virtualSettings/SET_STATE',
      payload: {
        isSpeaker2Open: false,
      },
    })
  }


  render() {
    const {
      virtualSettings: { isSpeaker2Open },
    } = this.props

    return (
      <>
        <Modal
          visible={isSpeaker2Open}
          style={{
            top: 0,
            // display: 'flex',
            maxHeight: '100vh',
          }}
          wrapClassName="agendaModal"
          maskClosable={false}
          mask
          width="70rem"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={
            <CloseCircleOutlined
              style={{
                color: 'black',
                fontSize: '25px',
                width: '56px',
                height: '56px',
                left: '0px',
              }}
            />
          }
          maskStyle={{ backgroundColor: 'rgba(15, 12, 41, 0.9)' }}
          // destroyOnClose
        >
          <Row gutter={24}>
            <Col span={24} className="text-center">
              <h2>Meet The Speakers</h2>
            </Col>
          </Row>
          <Row gutter={0} id="modalContent" className="modalContent">
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 15rem)">
              {/* <Skeleton loading={loading} active> */}
              {/* <Tabs tabPosition={isVirtualMobileView ? 'top' : 'left'}> */}
              {/* {showcase} */}
              {/* </Tabs> */}
              {/* </Skeleton> */}
              <Col span={24}>
                <iframe
                  title="How To"
                  style={{ width: '100%', height: '100vh' }}
                  src="resources/documents/Speakers Gallery.pdf"
                  allowFullScreen
                />
              </Col>
            </Scrollbars>
          </Row>
        </Modal>
      </>
    )
  }
}

export default Speaker2
