import React from 'react'
import { Modal, Col, Tabs, Row } from 'antd'
import { connect } from 'react-redux'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Scrollbars } from 'react-custom-scrollbars'

const { TabPane } = Tabs

const mapStateToProps = ({ virtualSettings, agenda, virtualEvent }) => ({
  virtualSettings,
  agenda,
  virtualEvent,
})

@connect(mapStateToProps)
class Agenda2 extends React.Component {
  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'virtualSettings/SET_STATE',
      payload: {
        isAgenda2Open: false,
      },
    })
  }

  render() {
    const {
      virtualSettings: { isAgenda2Open },
    } = this.props

    return (
      <>
        <Modal
          visible={isAgenda2Open}
          style={{
            top: 0,
            // display: 'flex',
            maxHeight: '100vh',
          }}
          wrapClassName="agendaModal"
          maskClosable={false}
          mask
          width="70rem"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={
            <CloseCircleOutlined
              style={{
                color: 'black',
                fontSize: '25px',
                width: '56px',
                height: '56px',
                left: '0px',
              }}
            />
          }
          maskStyle={{ backgroundColor: 'rgba(15, 12, 41, 0.9)' }}
          // destroyOnClose
        >
          <Row gutter={0} id="modalContent" className="modalContent">
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 15rem)">
              <Col span={24}>
                {/* <Skeleton loading={loading} active> */}
                {/* <Tabs tabPosition={isVirtualMobileView ? 'top' : 'left'}> */}
                {/* {showcase} */}
                {/* </Tabs> */}
                {/* </Skeleton> */}
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Day 1" key="1">
                    <Col span={24}>
                      <iframe
                        title="How To"
                        style={{ width: '100%', height: '100vh' }}
                        src="resources/documents/Day 1 Agenda.pdf"
                        allowFullScreen
                      />
                    </Col>
                  </TabPane>
                  <TabPane tab="Day 2" key="2">
                    <Col span={24}>
                      <iframe
                        title="How To"
                        style={{ width: '100%', height: '100vh' }}
                        src="resources/documents/Day 2 Agenda.pdf"
                        allowFullScreen
                      />
                    </Col>
                  </TabPane>
                </Tabs>
              </Col>
            </Scrollbars>
          </Row>
        </Modal>
      </>
    )
  }
}

export default Agenda2
