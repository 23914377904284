import React from 'react'
import { Layout } from 'antd'
import styles from './style.module.scss'

class LoginLayout extends React.PureComponent {
  render() {
    const { children } = this.props
    return (
      <Layout>
        <Layout.Content>
          <div className={styles.container}>
            <div className={styles.left}>
              <div className={`${styles.headerlayout} text-center`}>
                <img
                  src="resources/images/logo-inverse-homenew.png"
                  alt="Wiz365 Logo"
                  className={styles.brandlogo}
                />
              </div>
              <div className={styles.mainlayout}>
                <section>{children}</section>
              </div>
              {/* <div className={`${styles.footer} text-center`}>
                  <img
                    src="resources/images/presentedby.png"
                    alt="Sponsor Logos"
                    className={styles.sponsorlogo}
                  />
                </div> */}
            </div>
            {process.env.REACT_APP_LOGIN_BG === 'video' ? (
              <div
                className={styles.right}
                style={{ backgroundImage: 'url("resources/images/LandingPage.jpg")' }}
              >
                <video id="bgvid" playsinline autoPlay muted loop>
                  <source src="resources/videos/login-bg.mp4" type="video/mp4" />
                </video>
              </div>
            ) : (
              <div
                className={styles.right}
                style={{ backgroundImage: 'url("resources/images/LandingPage.jpg")' }}
              />
            )}
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}

export default LoginLayout
