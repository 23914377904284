import React from 'react'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
// import { push } from 'react-router-redux'
import {
  pubnubPublish,
  pubnubFetchMessages,
  savePoll,
  savePollResponse,
  fetchPolls,
  // approvePolls,
  publishPolls,
  emailNotes,
  hidePolls,
  // requestRaiseHand,
  getAllHandsRaised,
  raiseHandRequestStatus,
  fetchAvailableVMixSlots,
  assignVmixCall,
  releaseVmixCall,
} from 'services/virtual/chat'
import QuestionFormat from 'components/LayoutComponents/Virtual/StreamFeatures/LayoutComponent/QuestionFormat'
import { message, notification } from 'antd'
import actions from './actions'

export function* SEND_MESSAGE(data) {
  try {
    const state = yield select()
    const {
      Chat: { pubnub },
      virtualEvent: { currentSession },
      // agenda: {agendas}
    } = state

    console.log('checking currentsession')
    console.log(currentSession)
    const idToSend = currentSession
    /* Modification For Flipkart */
    // const idToSend = Object.keys(agendas).length !== 0 ? Object.keys(agendas)[0] : Object.keys(currenSceneAgenda)[0]
    const publishData = {
      channelID: idToSend,
      message: data.payload.message,
      pubnub,
    }

    const result = yield call(pubnubPublish, publishData)
    const utcSeconds = Math.round(result.timetoken / 10000000)
    const d = new Date(0) // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds)

    const toAdd = {
      position: 'right',
      type: 'text',
      category: data.payload.message.type,
      text: data.payload.message.content,
      date: d,
      status: 'recieved',
      title: data.payload.message.title,
      avatar: data.payload.message.avatar,
      notch: false,
      replyButton: true,
    }

    if (Object.keys(data.payload.message.reply).length > 0) {
      toAdd.reply = data.payload.message.reply
    }

    yield put({
      type: actions.SET_MESSAGE,
      payload: {
        key: 'chat',
        countType: 'unreadChatCount',
        count: 0,
        toAdd,
      },
    })
  } catch (err) {
    console.log(err.status)
    console.log(err)
    console.log(err.reponse)
    yield put({
      type: actions.SET_MESSAGE,
      payload: {
        key: 'chat',
        countType: 'unreadChatCount',
        count: 0,
        toAdd: {
          position: 'right',
          type: 'text',
          category: data.payload.message.type,
          text: data.payload.message.content,
          status: 'warning',
          title: data.payload.message.title,
          avatar: data.payload.message.avatar,
          notch: false,
        },
      },
    })
  }
}

export function* FETCH_MESSAGES(data) {
  try {
    const state = yield select()
    const {
      virtualUser: { id },
      streamFeature: { currentSessionDetails },
    } = state
    console.log('virtualuser', id)
    console.log('virtualuser2', currentSessionDetails)
    const totalModerators = currentSessionDetails.moderators.length
    const fetchResult = yield call(pubnubFetchMessages, data.payload)
    console.log('virtualuser3', fetchResult)

    const chat = []
    const qanda = {}
    Object.entries(fetchResult.channels).map(key => {
      key[1].forEach(obj => {
        const utcSeconds = Math.round(obj.timetoken / 10000000)
        const d = new Date(0) // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds)
        let actualText = obj.message.content
        let showTitleAvatar = true
        if (obj.message.type === 'qanda') {
          if (totalModerators > 0) {
            if (obj.message.moderationStatus === 'sentforapproval') {
              showTitleAvatar = false
              actualText = (
                <QuestionFormat
                  messageID={obj.message.message_id}
                  avatarURL={obj.message.avatar}
                  title={obj.message.title}
                  question={obj.message.content}
                  quesStatus={obj.message.moderationStatus}
                />
              )
            }
          }
        }
        const finalJSON = {
          message_id: obj.message.message_id,
          position: obj.message.publisher === id ? 'right' : 'left',
          type: 'text',
          category: obj.message.type,
          originalPublisher: obj.message.publisher,
          text: actualText,
          date: d,
          title: showTitleAvatar ? obj.message.title : '',
          notch: false,
          avatar: showTitleAvatar ? obj.message.avatar : '',
          replyButton: true,
        }
        if (Object.prototype.hasOwnProperty.call(obj.message, 'reply')) {
          if (Object.keys(obj.message.reply).length > 0) {
            finalJSON.reply = {
              photoURL: obj.message.reply.avatar,
              title: obj.message.reply.title,
              titleColor: '#FF0000',
              message: obj.message.reply.message,
            }
          }
        }

        if (obj.message.type === 'liveChat') {
          chat.push(finalJSON)
        } else if (obj.message.type === 'qanda') {
          qanda[obj.message.message_id] = finalJSON
        }
      })
      return ''
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        chat,
        qanda,
      },
    })
  } catch (err) {
    console.log(err)
    console.log(err.status)
  }
}

export function* SEND_QUESTION(data) {
  try {
    const state = yield select()
    const {
      streamFeature: { currentSessionDetails, assignedSessions },
      Chat: { pubnub },
      virtualEvent: { currentSession },
      virtualUser: { roles },
      // agenda: {agendas}
    } = state

    const idToSend = currentSession
    /* Modification For Flipkart */
    // const idToSend = Object.keys(agendas).length !== 0 ? Object.keys(agendas)[0] : Object.keys(currenSceneAgenda)[0]
    const totalModerators = currentSessionDetails.moderators.length
    const publishData = {
      channelID: totalModerators > 0 ? `${idToSend}-moderator` : idToSend,
      message: data.payload.message,
      pubnub,
    }
    const result = yield call(pubnubPublish, publishData)
    const utcSeconds = Math.round(result.timetoken / 10000000)
    const d = new Date(0) // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds)
    let actualText = data.payload.message.content
    if (totalModerators > 0) {
      let thequesStatus = 'pending'
      if (roles.includes('ROLE_MODERATOR')) {
        if (assignedSessions.includes(currentSession)) {
          thequesStatus = 'sentforapproval'
        }
      }
      actualText = (
        <QuestionFormat
          messageID={data.payload.message.message_id}
          avatarURL={data.payload.message.avatar}
          title={data.payload.message.title}
          question={data.payload.message.content}
          quesStatus={thequesStatus}
        />
      )
    }
    const toAdd = {
      message_id: data.payload.message.message_id,
      position: 'right',
      type: 'text',
      category: data.payload.message.type,
      text: actualText,
      date: d,
      title: totalModerators <= 0 ? data.payload.message.title : '',
      avatar: totalModerators <= 0 ? data.payload.message.avatar : '',
      status: 'recieved',
      notch: false,
    }

    if (Object.keys(data.payload.message.reply).length > 0) {
      toAdd.reply = data.payload.message.reply
    }

    yield put({
      type: actions.SET_QUESTION,
      payload: {
        key: 'qanda',
        countType: 'unreadqndaCount',
        count: 0,
        toAdd,
        message_id: data.payload.message.message_id,
      },
    })
  } catch (err) {
    console.log(err.status)
    console.log(err)
    console.log(err.reponse)
    yield put({
      type: actions.SET_QUESTION,
      payload: {
        key: 'qanda',
        countType: 'unreadqndaCount',
        count: 0,
        message_id: data.payload.message.message_id,
        toAdd: {
          message_id: data.payload.message.message_id,
          position: 'right',
          type: 'text',
          category: data.payload.message.type,
          text: data.payload.message.content,
          title: data.payload.message.title,
          avatar: data.payload.message.avatar,
          status: 'warning',
          notch: false,
        },
      },
    })
  }
}

export function* SEND_CREATE_POLL(data) {
  try {
    const state = yield select()
    const {
      // streamFeature: { currentSessionDetails },
      // Chat: { pubnub },
      virtualEvent: { currentSession },
    } = state

    // const totalModerators = currentSessionDetails.moderators.length
    // const publishData = {
    //   channelID: `${currentSession}-polling`,
    //   message: data.payload.message,
    //   pubnub,
    // }
    // const result = yield call(pubnubPublish, publishData)
    // const utcSeconds = Math.round(result.timetoken / 10000000)
    const d = Date.now(0) // The 0 there is the key, which sets the date to the epoch
    // d.setUTCSeconds(utcSeconds)

    const originalModerationStatus = data.payload.message.moderationStatus
    const tempMessage = data.payload.message
    tempMessage.moderationStatus = 'approved'
    tempMessage.active = false
    const toAdd = {
      message: tempMessage,
      date: d,
    }

    yield put({
      type: actions.SET_POLLING,
      payload: {
        key: 'polling',
        toAdd,
        message_id: data.payload.message.message_id,
      },
    })

    const dbSave = {
      message_id: data.payload.message.message_id,
      moderationStatus: originalModerationStatus,
      publisher: data.payload.message.publisher,
      question: data.payload.message.question,
      options: data.payload.message.options,
      answered: false,
      title: data.payload.message.title,
      avatar: data.payload.message.avatar,
      session_id: currentSession,
      channelID: `${currentSession}-polling`,
      // timestamp: result.timetoken,
      timestamp: d,
    }

    yield call(savePoll, dbSave)
  } catch (err) {
    console.log(err.status)
    console.log(err)
    console.log(err.reponse)
    yield put({
      type: actions.SET_POLLING,
      payload: {
        key: 'polling',
        toAdd: {
          message: data.payload.message,
        },
        message_id: data.payload.message.message_id,
      },
    })
  }
}

export function* ALLOW_QANDA(data) {
  try {
    const state = yield select()
    const {
      Chat: { pubnub },
      virtualEvent: { currentSession },
      streamFeature: { qanda },
    } = state

    const theQuestion = qanda[data.payload.messageID]
    const messageToSend = {
      avatar: theQuestion.text.props.avatarURL,
      content: theQuestion.text.props.question,
      message_id: theQuestion.text.props.messageID,
      moderationStatus: 'approved',
      publisher: theQuestion.originalPublisher,
      reply: theQuestion.reply,
      title: theQuestion.text.props.title,
      type: 'qanda',
    }

    /* tweak for flipkart */
    const idTobeSend = currentSession
    // const idTobeSend = Object.keys(agendas).length !== 0 ? Object.keys(agendas)[0] : Object.keys(currenSceneAgenda)[0]
    const publishData = {
      channelID: idTobeSend,
      message: messageToSend,
      pubnub,
    }
    yield call(pubnubPublish, publishData)

    const publishDatatoModerator = {
      channelID: `${idTobeSend}-moderator`,
      message: messageToSend,
      pubnub,
    }
    yield call(pubnubPublish, publishDatatoModerator)

    // messageToSend.type = "removeqanda"
    // const messageToSendModerator = {
    //   channelID: `${currentSession}-moderator`,
    //   message: messageToSend,
    //   pubnub,
    // }
    // yield call(pubnubPublish, messageToSendModerator)
  } catch (err) {
    console.log(err.status)
    console.log(err)
    console.log(err.reponse)
  }
}

export function* ALLOW_POLL(data) {
  try {
    const state = yield select()
    const {
      Chat: { pubnub },
      virtualEvent: { currentSession },
      streamFeature: { polling },
      virtualUser: { accessToken },
    } = state

    const thePoll = polling[data.payload.messageID]
    thePoll.message.active = true
    const tempMessageToSend = thePoll.message
    tempMessageToSend.moderationStatus = 'preapproved'
    tempMessageToSend.answered = false
    const publishData = {
      channelID: `${currentSession}-polling`,
      message: tempMessageToSend,
      pubnub,
    }
    yield call(pubnubPublish, publishData)
    // yield call(pubnubPublish, messageToSendModerator)

    // thePoll.message.active = true
    // thePoll.date = Date.now()
    console.log('checking poll')
    console.log(thePoll.message)
    console.log(data.payload.messageID)
    console.log(thePoll)
    yield put({
      type: actions.SET_POLLING,
      payload: {
        key: 'polling',
        toAdd: thePoll,
        message_id: data.payload.messageID,
      },
    })
    const dbSave = {
      messageID: data.payload.messageID,
      token: accessToken,
    }
    // yield call(approvePolls, dbSave)
    yield call(publishPolls, dbSave)
  } catch (err) {
    console.log(err.status)
    console.log(err)
    console.log(err.reponse)
  }
}

export function* HIDE_POLL(data) {
  try {
    const state = yield select()
    const {
      Chat: { pubnub },
      streamFeature: { polling },
      virtualEvent: { currentSession },
      virtualUser: { accessToken },
    } = state
    const thePoll = polling[data.payload.messageID]
    thePoll.message.active = false
    const publishData = {
      channelID: `${currentSession}-polling`,
      message: { type: 'removePoll', messageID: data.payload.messageID },
      pubnub,
    }
    yield call(pubnubPublish, publishData)
    yield put({
      type: actions.SET_POLLING,
      payload: {
        key: 'polling',
        toAdd: thePoll,
        message_id: data.payload.messageID,
      },
    })
    // yield call(pubnubPublish, messageToSendModerator)

    // thePoll.message.active = true
    // thePoll.date = Date.now()
    // yield put({
    //   type: actions.SET_POLLING,
    //   payload: {
    //     key: 'polling',
    //     toAdd: thePoll,
    //     message_id: data.payload.messageID,
    //   },
    // })
    const dbSave = {
      messageID: data.payload.messageID,
      token: accessToken,
    }
    // yield call(approvePolls, dbSave)
    yield call(hidePolls, dbSave)
  } catch (err) {
    console.log(err.status)
    console.log(err)
    console.log(err.reponse)
  }
}

export function* ANSWER_POLL(data) {
  try {
    const state = yield select()
    const {
      Chat: { pubnub },
      virtualEvent: { currentSession },
      streamFeature: { polling },
      virtualUser: { accessToken },
    } = state

    const thePoll = polling[data.payload.messageID]
    thePoll.message.answered = true
    const tempVotes = thePoll.message.options[data.payload.optionID].votes
    thePoll.message.options[data.payload.optionID].votes = tempVotes + 1

    yield put({
      type: actions.SET_POLLING,
      payload: {
        key: 'polling',
        toAdd: thePoll,
        message_id: data.payload.messageID,
      },
    })

    const messageToPublish = {
      type: 'updatePoll',
      messageID: data.payload.messageID,
      optionID: data.payload.optionID,
    }
    const publishData = {
      channelID: `${currentSession}-polling`,
      message: messageToPublish,
      pubnub,
    }
    yield call(pubnubPublish, publishData)

    const dbSave = {
      sessionID: currentSession,
      messageID: data.payload.messageID,
      optionID: data.payload.optionID,
      token: accessToken,
    }
    yield call(savePollResponse, dbSave)
    notification.success({
      message: 'Points Added',
      duration: 3,
      description: 'Points have been successfully added to the leaderboard.',
    })
  } catch (err) {
    console.log(err.status)
    console.log(err)
    console.log(err.reponse)
  }
}

export function* FETCH_POLL() {
  try {
    const state = yield select()
    const {
      virtualEvent: { currentSession },
      virtualUser: { accessToken },
    } = state

    const dbFetch = {
      token: accessToken,
      sessionID: currentSession,
    }
    const result = yield call(fetchPolls, dbFetch)
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          polling: result.data,
        },
      })
    }
  } catch (err) {
    console.log('inside fetching Polls error')
    console.log(err.status)
    console.log(err)
    console.log(err.reponse)
  }
}

export function* EMAIL_NOTE() {
  try {
    const state = yield select()
    const {
      streamFeature: { notes },
      virtualUser: { accessToken },
    } = state

    const result = yield call(emailNotes, { notes, accessToken })
    if (result.status === 200) {
      message.success('Notes Emailed Successfully')
    }
  } catch (err) {
    console.log('email error')
    console.log(err.status)
    console.log(err)
    console.log(err.reponse)
  }
}

export function* RAISE_HAND() {
  try {
    const state = yield select()
    const {
      Chat: { pubnub },
      virtualEvent: { currentSession },
      virtualUser: { id, name },
    } = state

    const d = Date.now(0)
    const messageToBeSent = {
      user_id: id,
      activeStatus: true,
      name,
      timestamp: d,
    }

    yield put({
      type: actions.SET_STATE,
      payload: {
        isHandRaised: true,
      },
    })

    const messageToPublish = {
      type: 'raiseHandRequest',
      theMessage: messageToBeSent,
    }
    const publishData = {
      channelID: `${currentSession}-raisehand`,
      message: messageToPublish,
      pubnub,
    }
    yield call(pubnubPublish, publishData)

    // yield call(requestRaiseHand, {sessionId:currentSession,accessToken})
  } catch (err) {
    console.log(err.status)
    console.log(err)
    console.log(err.reponse)
  }
}

export function* REVOKE_HAND() {
  try {
    const state = yield select()
    const {
      Chat: { pubnub },
      virtualEvent: { currentSession },
      virtualUser: { id, name },
    } = state

    const d = Date.now(0)
    const messageToBeSent = {
      user_id: id,
      activeStatus: false,
      name,
      timestamp: d,
    }

    yield put({
      type: actions.SET_STATE,
      payload: {
        isHandRaised: false,
      },
    })

    const messageToPublish = {
      type: 'revokeHandRequest',
      theMessage: messageToBeSent,
    }
    const publishData = {
      channelID: `${currentSession}-raisehand`,
      message: messageToPublish,
      pubnub,
    }
    yield call(pubnubPublish, publishData)

    // yield call(requestRaiseHand, {sessionId:currentSession,accessToken})
  } catch (err) {
    console.log(err.status)
    console.log(err)
    console.log(err.reponse)
  }
}

export function* VALIDATE_RAISE_HAND(data) {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state
    const result = yield call(assignVmixCall, { callid: data.payload.callid, accessToken })
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          backstageVisible: true,
          backstageURL: data.payload.url,
          isHandRaised: false,
          raiseHandLoading: false,
        },
      })
    }
  } catch (err) {
    console.log(err.status)
    console.log(err)
    console.log(err.reponse)
    message.error('Slots filled up. Kindly raise the hand again.')
    yield put({
      type: actions.SET_STATE,
      payload: {
        isHandRaised: false,
        raiseHandLoading: false,
      },
    })
  }
}

export function* APPROVE_RAISE_HAND(data) {
  try {
    const state = yield select()
    const {
      Chat: { pubnub },
      streamFeature: {
        currentSessionDetails: { backstageToggle, backstageType, backendURL },
        raiseHandRequests,
      },
      virtualEvent: { currentSession },
    } = state
    const d = Date.now(0)

    if (backstageType === 'vmix') {
      const result = yield call(fetchAvailableVMixSlots, { sessionID: currentSession })
      if (result.status === 200) {
        if (Object.keys(result.data).length !== 0) {
          const messageToBeSent = {
            user_id: data.payload.userid,
            url: result.data.url,
            callid: result.data.id,
            backstageToggle,
            backstageType,
            timestamp: d,
          }
          const messageToPublish = {
            type: 'acceptRaiseHand',
            theMessage: messageToBeSent,
          }

          const publishData = {
            channelID: data.payload.userid,
            message: messageToPublish,
            pubnub,
          }
          yield call(pubnubPublish, publishData)
          const tempraiseHandRequests = raiseHandRequests
          if (Object.prototype.hasOwnProperty.call(tempraiseHandRequests, data.payload.userid)) {
            delete tempraiseHandRequests[data.payload.userid]
            yield put({
              type: actions.SET_STATE,
              payload: {
                raiseHandRequests: tempraiseHandRequests,
              },
            })
            message.success('Invitation Sent Successfully')
          }
        } else {
          message.error('No Slots available. Wait for some time before accepting new requests.')
        }
      }
    } else {
      const messageToBeSent = {
        user_id: data.payload.userid,
        url: backendURL,
        backstageToggle,
        backstageType,
        timestamp: d,
      }
      const messageToPublish = {
        type: 'acceptRaiseHand',
        theMessage: messageToBeSent,
      }

      const publishData = {
        channelID: data.payload.userid,
        message: messageToPublish,
        pubnub,
      }
      yield call(pubnubPublish, publishData)
      const tempraiseHandRequests = raiseHandRequests
      if (Object.prototype.hasOwnProperty.call(tempraiseHandRequests, data.payload.userid)) {
        delete tempraiseHandRequests[data.payload.userid]
        yield put({
          type: actions.SET_STATE,
          payload: {
            raiseHandRequests: tempraiseHandRequests,
          },
        })
        message.success('Invitation Sent Successfully')
      }
    }

    // yield call(requestRaiseHand, {sessionId:currentSession,accessToken})
  } catch (err) {
    console.log(err.status)
    console.log(err)
    console.log(err.reponse)
    message.error('Issue occured in accepting the request')
  }
}

export function* FETCH_RAISE_HANDS() {
  try {
    const state = yield select()
    const {
      virtualEvent: { currentSession },
      virtualUser: { accessToken },
    } = state

    const dbFetch = {
      token: accessToken,
      sessionID: currentSession,
    }
    const result = yield call(raiseHandRequestStatus, dbFetch)
    if (result.status === 200) {
      // yield put({
      //   type: actions.SET_STATE,
      //   payload: {
      //     polling: result.data,
      //   },
      // })
      console.log('what is the status')
      console.log(result.data)
    }
  } catch (err) {
    console.log('inside fetching FETCH_RAISE_HANDS error')
    console.log(err.status)
    console.log(err)
    console.log(err.reponse)
  }
}

export function* FETCH_ALL_RAISE_HANDS() {
  try {
    const state = yield select()
    const {
      virtualEvent: { currentSession },
    } = state

    const result = yield call(getAllHandsRaised, { sessionID: currentSession })
    if (result.status === 200) {
      console.log('how is the data')
      console.log(result.data)
      // yield put({
      //   type: actions.SET_STATE,
      //   payload: {
      //     polling: result.data,
      //   },
      // })
    }
  } catch (err) {
    console.log('inside fetching FETCH_ALL_RAISE_HANDS error')
    console.log(err.status)
    console.log(err)
    console.log(err.reponse)
  }
}

export function* RELEASE_VMIX_CALL() {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state
    const result = yield call(releaseVmixCall, { accessToken })
    if (result.status === 200) {
      message.success('You are now back to the Auditorium.')
    }
  } catch (err) {
    console.log(err.status)
    console.log(err)
    console.log(err.reponse)
    message.error('Error leaving the backstage')
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SEND_MESSAGE, SEND_MESSAGE),
    takeEvery(actions.FETCH_MESSAGES, FETCH_MESSAGES),
    takeEvery(actions.SEND_QUESTION, SEND_QUESTION),
    takeEvery(actions.ALLOW_QANDA, ALLOW_QANDA),
    takeEvery(actions.SEND_CREATE_POLL, SEND_CREATE_POLL),
    takeEvery(actions.ALLOW_POLL, ALLOW_POLL),
    takeEvery(actions.ANSWER_POLL, ANSWER_POLL),
    takeEvery(actions.HIDE_POLL, HIDE_POLL),
    takeEvery(actions.FETCH_POLL, FETCH_POLL),
    takeEvery(actions.EMAIL_NOTE, EMAIL_NOTE),
    takeEvery(actions.RAISE_HAND, RAISE_HAND),
    takeEvery(actions.APPROVE_RAISE_HAND, APPROVE_RAISE_HAND),
    takeEvery(actions.FETCH_RAISE_HANDS, FETCH_RAISE_HANDS),
    takeEvery(actions.FETCH_ALL_RAISE_HANDS, FETCH_ALL_RAISE_HANDS),
    takeEvery(actions.REVOKE_HAND, REVOKE_HAND),
    takeEvery(actions.VALIDATE_RAISE_HAND, VALIDATE_RAISE_HAND),
    takeEvery(actions.RELEASE_VMIX_CALL, RELEASE_VMIX_CALL),
  ])
}
