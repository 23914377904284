import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'antd'
// import { Scrollbars } from 'react-custom-scrollbars'
import { CloseCircleOutlined } from '@ant-design/icons'
import actions from 'redux/virtual/event/actions'

// import './download.less'

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
@connect(({ virtualSettings, virtualEvent }) => ({ virtualSettings, virtualEvent }))
class HowTo extends React.Component {
  openDownloadable = event => {
    const podID = event.target.getAttribute('data-pathTo')
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentClickSection: podID,
        isDocumentListVisible: true,
      },
    })
  }

  downloadBriefcase = () => {
    const {
      virtualEvent: { briefcase },
    } = this.props

    Object.entries(briefcase).map(key => {
      window.open(key[1].fileURL)
      return ''
    })
  }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'virtualSettings/CHANGE_SETTING',
      payload: {
        setting: 'isHowtoOpen',
        value: false,
      },
    })
  }

  sendEmail = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        briefcaseEmailLoading: true,
      },
    })
    dispatch({
      type: actions.SEND_BRIEFCASE_EMAIL,
      payload: {},
    })
  }

  render() {
    const {
      virtualSettings: { isHowtoOpen },
    } = this.props

    return (
      <>
        <Modal
          visible={isHowtoOpen}
          style={{
            top: '0px',
          }}
          wrapClassName="downloadModal"
          width="70rem"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={<CloseCircleOutlined style={{ color: 'white', fontSize: '25px' }} />}
          destroyOnClose
        >
          <div
            className="mainiframe"
            style={{ position: 'relative', width: '100%', paddingTop: '56.25%' }}
          >
            <iframe
              title="How To"
              style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
              src="https://vimeo.com/event/3899551/embed/8a6226f6a6"
              allowFullScreen
            />
          </div>
        </Modal>
      </>
    )
  }
}

export default HowTo
